// @flow

import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import autoScroll from '../../components/Autoscroll';
// import Button from '../../components/Button';
import Section from '../../components/Section';
import Button from '../../components/Button';
import styles from './home.module.scss';

import LogoM6 from '../../assets/img/m6-logo.png';
import LogoPluto from '../../assets/img/pluto-logo.png';
import LogoFrequency from '../../assets/img/frequency-logo.png';
import LogoAmagi from '../../assets/img/amagi-logo.png';
import LogoGracenote from '../../assets/img/gracenote-logo.png';
import DataProcessing from '../../assets/img/data-processing.png';
import Developer from '../../assets/img/developer.png';

import translate from './translate.json';

// $FlowFixMe
import metaValues from '../../../config';
import translation from '../../translate';

const HomeScreen = (): any => {
  const { defaultTitle, allowedLanguages, defaultLanguage } = metaValues;
  const { language = defaultLanguage } = useSelector(
    ({ common }: any) => common
  );
  const title = `${translation('_SEO_TITLE1', language)} - ${defaultTitle}`;
  // const list = translation('_HOME_LIST', language);
  const navigate = useNavigate();

  useEffect(() => {
    if (!allowedLanguages.includes(language)) {
      return navigate('/404page');
    }
  }, [language]);

  return (
    <section>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={translation('_SEO_TEXT', language).join(' ')}
        />
        <meta
          property="keywords"
          content={translation('_SEO_KEYWORDS', language)}
        />
        <meta property="og:title" content={title} />
        <meta
          property="og:image"
          content={`${metaValues.domain}/public/data.svg`}
        />
      </Helmet>

      <Section>
        <div className={styles.content}>
          <div className={styles.grid}>
            <div>
              <h1
                dangerouslySetInnerHTML={{
                  __html: translation('title', language, translate)
                }}
              />
              <p>{translation('paragraph1', language, translate)}</p>
              <p>{translation('paragraph2', language, translate)}</p>
            </div>
            <div>
              <img
                src={DataProcessing}
                className={styles.dataImage}
                alt="Data Processing"
              />
            </div>
          </div>

          <ul className={styles.logos}>
            <li>
              <img src={LogoM6} alt="M6" />
            </li>
            <li>
              <img src={LogoPluto} alt="Pluto" />
            </li>
            <li>
              <img src={LogoFrequency} alt="Frequency" />
            </li>
            <li>
              <img src={LogoAmagi} alt="Amagi" />
            </li>
            <li>
              <img src={LogoGracenote} alt="Gracenote" />
            </li>
          </ul>

          <div className={styles.grid2}>
            <div>
              <p>{translation('paragraph3', language, translate)}</p>
              <p>{translation('paragraph4', language, translate)}</p>
              <p>{translation('paragraph5', language, translate)}</p>
            </div>
            <div>
              <img
                src={Developer}
                className={styles.dataImage}
                alt="EPG Pipeline Development"
              />
            </div>
          </div>

          <div className={styles.book}>
            <div>
              <h2>{translation('subtitle', language, translate)}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: translation('head1', language, translate)
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: translation('head2', language, translate)
                }}
              />
            </div>
            <div>
              <h2>{translation('title2', language, translate)}</h2>
              <p>{translation('textPromo1', language, translate)}</p>
              <p>{translation('textPromo2', language, translate)}</p>
            </div>
          </div>
          <center>
            <span
              onClick={(event: any) => {
                window.location =
                  'mailto:react.app.top@gmail.com?subject=Book EPG Demo';
                event.preventDefault();
              }}
            >
              <Button size="large">Book a demo</Button>
            </span>
          </center>

          <Link to={`/${language}/wizard`} onClick={autoScroll}>
            Wizard
          </Link>
        </div>
      </Section>
    </section>
  );
};

export default HomeScreen;
