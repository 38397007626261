import _ from 'lodash';
import en from './_en';
import ua from './_ua';
import ru from './_ru';
import fr from './_fr';
import de from './_de';

const dictionaries = {
  ua,
  en,
  fr,
  ru,
  de
};

const translate = (name, lang, dictionary = {}) => {
  const currentDict = dictionaries[lang] || en;
  return _.get(dictionary, `${lang}.${name}`) || currentDict[name] || en[name];
};

export default translate;
