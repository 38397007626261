const values = {
  domain: 'https://www.epg-pipeline.com',
  defaultLanguage: 'en',
  allowedLanguages: ['en', 'de', 'ua', 'ru', 'fr'],
  defaultTitle: 'EPG-PIPELINE.COM',
  defaultDescription: 'Build personal EPG-pipeline in minutes',
  defaultKeywords:
    'epg ingestion, epg pipeline, epg parsing, epg processing pipeline, epg data pipeline'
};

export default values;
