const translate = {
  _HOME_CONTACT: 'Contact',
  _BOOK_DEMO: 'Réserver une démo',
  _SEO_TITLE1:
    'Créez un pipeline d’ingestion EPG en ligne en quelques minutes !',
  _SEO_TEXT: [
    'Vous pouvez facilement créer votre propre fichier EPG/données',
    'à partir de plusieurs sources <span>SANS DÉVELOPPEURS</span>',
    'et <span>ARCHITECTES</span> en quelques minutes.',
    'Tout ce dont vous avez besoin - sélectionnez la source, configurez les mappages,',
    'les mutations et attendez le résultat.'
  ],
  _SEO_TITLE2: 'Qu’est-ce qu’un pipeline d’ingestion EPG ?',
  _SEO_TEXT2: [
    'Un pipeline d’ingestion EPG est un cadre essentiel en ingénierie des données conçu pour importer et traiter efficacement les données EPG de nombreuses sources vers un système centralisé de stockage ou d’analyse. Ce processus constitue la colonne vertébrale de la gestion des données, transformant des données brutes et variées en informations exploitables.',
    "Le pipeline comprend généralement trois étapes clés : extraire les données de sources variées, transformer les données pour qu'elles soient conformes aux exigences du système cible, et les charger dans un entrepôt de données ou dans le cloud. L'étape suivante peut être l'exportation dans n’importe quel format de fichier pour une utilisation dans d’autres systèmes."
  ],

  _WIZARD_TITLE: 'Composants du pipeline',
  _WIZARD_TEXT:
    'Pour commencer à construire votre pipeline - sélectionnez des éléments en haut et faites-les glisser vers les étapes du pipeline ci-dessous.',
  _WIZARD_TITLE1: 'Sources',
  _WIZARD_TITLE2: 'Mappages',
  _WIZARD_TITLE3: 'Mutations',
  _WIZARD_TITLE4: 'Préparer',
  _WIZARD_TITLE5: 'Mappage',
  _WIZARD_TITLE6: 'Postops',
  _WIZARD_TITLE7: 'Final',
  _WIZARD_CONFIGURE: 'Configurer',
  _WIZARD_SAVE: 'Sauvegarder'
};

export default translate;
