import React from 'react';
import _ from 'lodash';
import { Route, Routes } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';

import AppRoutes from './routes';
// import { getFacets } from './store/actions/home';
import Header from './components/Header';
import Footer from './components/Footer';
import styles from './app.module.scss';

const App = () => (
  <div className={styles.body}>
    <Header />
    <div className={styles.main}>
      <Routes>
        {_.map(AppRoutes, (route, index) => {
          const { element: Element } = route;

          // if (route.protected) {
          //   return (
          //     <ProtectedRoute {...route} key={index}>
          //       {component}
          //     </ProtectedRoute>
          //   );
          // }

          return <Route {...route} key={index} element={<Element />} />;
        })}
      </Routes>
    </div>
    <Footer />
  </div>
);

export default App;
