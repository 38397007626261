const translate = {
  _HOME_CONTACT: 'Связаться',
  _BOOK_DEMO: 'Забронировать демо',
  _SEO_TITLE1:
    'Создайте онлайн-обработку данных EPG INGESTION PIPELINE за считанные минуты',
  _SEO_TEXT: [
    'Вы можете легко создать свой собственный EPG/файл данных',
    'из нескольких источников <span>БЕЗ РАЗРАБОТЧИКОВ</span>',
    'и <span>АРХИТЕКТОРОВ</span> за минуты.',
    'Все, что вам нужно - выбрать источник, настроить сопоставление',
    'мутации и ждать результата.'
  ],
  _SEO_TITLE2: 'Что такое конвейер EPG?',
  _SEO_TEXT2: [
    'Конвейер EPG Ingestion Pipeline — это важная структура в разработке данных, предназначенная для эффективного импорта и обработки данных EPG из многих источников в централизованную систему хранения или анализа. Этот процесс образует основу управления данными, превращая необработанные и различные данные в практические идеи/элементы.',
    'Конвейер обычно включает три ключевых этапа: извлечение данных из различных источников, преобразование данных в соответствии с требованиями целевой системы и загрузка их в хранилище данных или облако. А следующим шагом может быть экспорт в любой формат файла для использования в других системах.'
  ],
  _WIZARD_TITLE: 'Компоненты Pipeline',
  _WIZARD_TEXT:
    'Чтобы начать создание конвейера, выберите элементы сверху и перетащите их к шагам конвейера ниже.',
  _WIZARD_TITLE1: 'Источники',
  _WIZARD_TITLE2: 'Сопоставление',
  _WIZARD_TITLE3: 'Мутации',
  _WIZARD_TITLE4: 'Подготовить',
  _WIZARD_TITLE5: 'Отображение',
  _WIZARD_TITLE6: 'Постопы',
  _WIZARD_TITLE7: 'Окончательный',
  _WIZARD_CONFIGURE: 'Настроить',
  _WIZARD_SAVE: 'Сохранить'
};

export default translate;
